import './style.css';

import { Typography, createMuiTheme } from '@material-ui/core';
import PizzahutLogo from './images/logo.svg';
import HomePageBannerImage from './images/home_page_banner.jpg';
import HomePageSectionImage from './images/home_page_section.jpg';
// import PrivacyPolicyPDF from './Privacy_Policy.pdf';
import React from 'react';
import { getYear } from '../../src/util/getYear';

const headingFontFamily = `"PizzaHut", Helvetica`;
const bodyFontFamily = `"SharpSans", Helvetica`;
const buttonBorderRadius = 4;
const primaryColor = '#E21216';
const secondaryColor = '#131313';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontWeightMedium: 600,
    useNextVariants: true,
    htmlFontSize: 16,
    h1: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: 1.2,
      letterSpacing: '-.9px',
      textTransform: 'uppercase',
      textShadow: '-2px 3px 0px #000000',
      textStroke: '1px #000000',
      '-webkit-text-stroke': '1px #000000'
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.5px',
      textAlign: 'justify',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
      textAlign: 'center',
      maxWidth: '80%',
      marginBottom: '1rem',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '1.25px',
    },
    button: {
      ...defaultTheme.typography.button,
      fontFamily: headingFontFamily,
      fontSize: '16px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: buttonBorderRadius,
          borderTopRightRadius: buttonBorderRadius,
        },
        '&:last-child': {
          borderBottomLeftRadius: buttonBorderRadius,
          borderBottomRightRadius: buttonBorderRadius,
        },
      },
    },
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: 'pizzahut',
  name: `Pizza Hut`,
  logo: PizzahutLogo,
  styles: {
    muiTheme: {
      ...theme,
    },
    buttonBorderRadius: buttonBorderRadius,
  },
  appName: '',
  // meta tag values are used to generate the link preview when sharing link on social media
  metaTags: {
    title: 'Pizza Hut Recycling',
    description: '',
    url: '',
    thumbnailImage: '',
  },
  footer: {
    text: `© ${getYear()} Pizza Hut, LLC. All Rights Reserved. The Pizza Hut Name, Logos And Related Marks Are Trademarks Of Pizza Hut, LLC. All Other Trademarks Are The Property Of Their Respective Owners.`,
    // privacyPolicyUrl: PrivacyPolicyPDF,
  },
  search: {
    title: {
      label: 'Find your local Recycling Guidelines',
      textTransform: 'capitalize',
      maxWidth: '500px',
    }
  },
  homePage: {
    bannerImage: HomePageBannerImage,
    headline: "Pizza boxes are recyclable",
    body: (
      <Typography variant='body1' color='textPrimary'>
        At Pizza Hut, we’re doing our part to make sure that pizza boxes don’t go to waste. All of our pizza boxes contain recycled material and we want to keep a good thing going by getting them back into the recycling stream. That’s why we’re working with industry partners to get the word out about pizza boxes being recyclable. It’s estimated that 79% of the population has access to recycling programs within their local communities for used pizza boxes (based on a study by Westrock, our key packaging supplier and one of the largest packaging manufacturers in the U.S.) So do your part, totally ghost the trash and recycle that pizza box today!
      </Typography>
    ),
    backgroundPosition: 'left top',
    sectionImage: HomePageSectionImage,
    recyclingGuidelinesInstructions: 'Use our lookup tool at the top of the page to learn more about the recycling guidelines in your area.',
    factsSection: {
      hide: true,
    },
    aboutSection: {
      hide: true,
    },
    guidelinesSection: {
      hide: true,
    },
    chartsSection: {
      hide: true,
    }
  },
  factsPage: {
    hide: true,
  },
  faqsPage: {
    hide: true,
  },
  emailFeedback: {
    isSupported: false,
    privacy: null,
  },
  navbar: {
    hide: true,
  },
  customTheme: {
    header: {
      backgroundColor: 'transparent',
    },
  }
};
